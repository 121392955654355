import dayjs from "dayjs";
import { useRouter } from "next/router";
import ExportedImage from "next-image-export-optimizer";
import { useContext } from "react";
import styled from "styled-components";

import { trackleadform } from "@/analytics/analytics";

import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";
import useMediaQuery from "@/design-system/useMediaQuery";

import SecondaryButton from "@/design-system-v2/Button/SecondaryButton";
import FlexContainer from "@/design-system-v2/FlexContainer";
import Typography from "@/design-system-v2/Typography";

import { useAppSelector } from "@/hooks/hooks";

import { PreviousRouteProvider } from "@/pages/_app";

import { courseUrl, getScrollPositionByPercentage, mentorUrl } from "@/utils/utils";

import { theme } from "@/theme";

const CardWrapper = styled(Box)`
  max-width: 390px;
  box-shadow: ${theme.shadows.primary["xs"]};

  .status {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dashboard_button {
    svg {
      width: 14px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 100%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .dashboard_button {
      width: fit-content;
    }
  }
`;

interface CardProps {
  status: string;
  type: string;
  index?: number;
  data: any;
  isUserMentor: boolean;
  isUserIg: boolean;
}

const PaidCard = ({ status, type, data, isUserMentor, isUserIg }: CardProps) => {
  const loginDetails = useAppSelector((state) => state.rootReducer.auth);
  const router = useRouter();
  const { previousRoute } = useContext(PreviousRouteProvider);
  const statusPillStyles = {
    ongoing: {
      color: theme.colors.moss[700],
      background: theme.colors.moss[100],
      text: "Ongoing",
    },
    upcoming: {
      color: theme.colors.purple[700],
      background: theme.colors.purple[100],
      text: "Upcoming",
    },
    completed: {
      color: theme.colors.indigo[700],
      background: theme.colors.indigo[100],
      text: "Completed",
    },
  };
  const typeStyles = {
    enrolled: {
      text: "Enrolled",
      color: theme.colors.grayCool,
      icon: "check-circle-broken",
    },
    transferred: {
      text: "Transferred",
      color: theme.colors.purple,
      icon: "arrow-circle-broken-up-right",
    },
    cancelled: {
      text: "Cancelled",
      color: theme.colors.error,
      icon: "x-circle",
    },
    upcoming: {
      text: "Upcoming",
      color: theme.colors.purple,
      icon: "clock",
    },
    ongoing: {
      text: "Ongoing",
      color: theme.colors.moss,
      icon: "star-01",
    },
    archived: {
      text: "Completed",
      color: theme.colors.blueDark,
      icon: "check-circle-broken",
    },
  };

  const startDateAvailable = data?.cohort?.timelines?.find(
    (elem) => elem.key === "START_DATE" || elem.key === "KICK_OFF" || elem.key === "COURSE_UNLOCKED"
  );
  const startDate = startDateAvailable?.meta?.time;
  const isHorizontal = !useMediaQuery(`(max-width:${theme.breakpoints.md})`);

  const getCourseImageSrc = (isHorizontal: boolean, courseType: string): string => {
    switch (courseType) {
    case "BIM_A":
      return `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/${
        isHorizontal ? "horizontal" : "vertical"
      }-BIM_A.svg`;
    case "BIM_C":
      return `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/${
        isHorizontal ? "horizontal" : "vertical"
      }-BIM_C.svg`;
    case "INT_DES":
      return `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/${
        isHorizontal ? "horizontal" : "vertical"
      }-INT_DES.svg`;
    default:
      return `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/${
        isHorizontal ? "horizontal" : "vertical"
      }-MCDC.svg`;
    }
  };

  return (
    <CardWrapper
      bg={
        type !== "cancelled" && type !== "archived"
          ? theme.colors.base.white
          : theme.colors.grayCool[100]
      }
      px={theme.spacing(2)}
      py={theme.spacing(2)}
      borderRadius={theme.radius(4)}
    >
      <FlexContainer flexDirection={isHorizontal ? "row" : "column"}>
        <Box>
          <ExportedImage
            src={getCourseImageSrc(isHorizontal, data?.cohort?.cohortType)}
            alt="creative"
            width={124}
            height={isHorizontal ? 208 : 96}
            style={{
              mixBlendMode: type !== "cancelled" && type !== "archived" ? "normal" : "luminosity",
              objectFit: isHorizontal ? "fill" : "cover",
              width: isHorizontal ? "auto" : "100%",
              borderRadius: theme.spacing(4),
            }}
          />
        </Box>
        <FlexContainer
          py={isHorizontal ? theme.spacing(4) : theme.spacing(4)}
          px={isHorizontal ? theme.spacing(6) : theme.spacing(3)}
          flexDirection={"column"}
        >
          <FlexContainer alignItems="center" gap={theme.spacing(1)}>
            <Icon
              color={typeStyles[type].color[500]}
              varient={"solid"}
              type={typeStyles[type].icon}
              size={16}
            />
            <Typography
              varient="textsm"
              weightVarient="semibold"
              color={typeStyles[type].color[700]}
            >
              {typeStyles[type].text}
            </Typography>

            {/* These badges are only visible for learner-hub */}
            {(status === "ongoing" || status === "upcoming" || status === "completed") && (
              <Box
                py={theme.spacing(1)}
                px={theme.spacing(2)}
                ml={1}
                className="status"
                borderRadius={theme.radius(4)}
                background={statusPillStyles[status].background}
              >
                <Typography varient="textxs" weightVarient="semibold">
                  {statusPillStyles[status].text}
                </Typography>
              </Box>
            )}
          </FlexContainer>
          <FlexContainer
            flexDirection={"column"}
            justifyContent={type === "enrolled" ? "flex-start" : "space-between"}
            flexGrow={"1"}
          >
            <Typography
              mt={isHorizontal ? theme.spacing(4) : theme.spacing(3)}
              varient={isHorizontal ? "displaysm" : "displayxs"}
              weightVarient="semibold"
              color={theme.colors.gray[800]}
            >
              {data?.cohort?.courseTitle}
            </Typography>
            <FlexContainer
              mt={isHorizontal ? theme.spacing(1) : theme.spacing(2)}
              alignItems={"center"}
              gap={theme.spacing(2)}
            >
              <Typography varient="textsm" weightVarient="bold" color={theme.colors.gray[700]}>
                {data?.cohort?.batchNumber?.replaceAll("-", " ")}
                {/* {getCohortNumber(data?.cohortUserData.batchNumber)} */}
              </Typography>
              <Typography varient="textsm" weightVarient="medium" color={theme.colors.gray[700]}>
                {dayjs(startDate).format("DD MMM'YY")}
              </Typography>
            </FlexContainer>
            {(type === "enrolled" || type === "upcoming" || type === "ongoing") && (
              <SecondaryButton
                fullWidth={!isHorizontal}
                color="neutral"
                suffixIcon={<Icon type={"arrow-narrow-up-right"} varient={"line"} />}
                size={"sm"}
                disabled={data?.cohort?.courseId === "INT_D_001"}
                mt={isHorizontal ? "auto" : theme.spacing(4)}
                label={
                  <Typography
                    varient={"textsm"}
                    weightVarient={"semibold"}
                    color={theme.colors.gray[800]}
                  >
                    {/* {isUserMentor || isUserIg
                      ? "Dashboard"
                      : loginDetails?.userProfile?.profileStatus ===
                        "FILLED_ONBOARDING_FORM"
                      ? "Dashboard"
                      : "Complete onboarding"} */}
                    {isUserMentor || isUserIg
                      ? "Dashboard"
                      : ["NOT_COMPLETED", "FILLED_BASIC_DETAILS", "FILLED_EDUCATION_FORM"].includes(
                        loginDetails?.userProfile?.profileStatus
                      )
                        ? "Complete onboarding"
                        : "Dashboard"}
                  </Typography>
                }
                className="dashboard_button"
                onClick={(e: any) => {
                  if (isUserMentor || isUserIg) {
                    const url = mentorUrl(
                      isUserIg,
                      data?.cohort?.productId,
                      data?.cohort?.tags,
                      data?.cohort?.onBoarding?.todoList?.length === 0
                    );
                    router.push(url);
                  } else {
                    const url = courseUrl(
                      data?.cohort?.productType,
                      data?.cohort?.productId,
                      data?.cohort?.tags,
                      ["NOT_COMPLETED", "FILLED_BASIC_DETAILS", "FILLED_EDUCATION_FORM"].includes(
                        loginDetails?.userProfile?.profileStatus
                      )
                    );
                    const btnText =
                      isUserMentor || isUserIg
                        ? "Dashboard"
                        : [
                          "NOT_COMPLETED",
                          "FILLED_BASIC_DETAILS",
                          "FILLED_EDUCATION_FORM",
                        ].includes(loginDetails?.userProfile?.profileStatus)
                          ? "Complete onboarding"
                          : "Dashboard";
                    const mixPanelTrackObj = {
                      user_id: loginDetails.userId,
                      name: loginDetails.userProfile.firstName,
                      email: loginDetails.userData.emailId,
                      cohortId: data?.cohort?.courseId,
                      ButtonText: btnText,
                      Page_path: window.location.pathname,
                      Current_Page: window.location.href,
                      Page_Referrer: previousRoute,
                      percent_scrolled: getScrollPositionByPercentage(),
                      loginDetails: loginDetails.userData,
                      isLoggedIn: loginDetails.isAuthenticated,
                    };
                    trackleadform("Button_Click", mixPanelTrackObj);
                    router.push(url);
                  }
                }}
              />
            )}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </CardWrapper>
  );
};

export default PaidCard;
