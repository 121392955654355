import { createSlice } from "@reduxjs/toolkit";

import { getCourseContent } from "@/client/lmsApiClient";

import { getConditionalData } from "@/utils/utils";

import { API_TYPE, EvaluationListStatus, FeedbackQuestionsType } from "./../utils/types.enums";

const initialState = {
  slotsData: [],
  selectedTab: "",
  selectedSlot: null,
  slotScreenStatus: "slotSelection",
  confirmPopupScreenStatus: "confirm",
  contentData: null,
  lmsNodeId: "",
  stateData: null,
  feedbackQuizData: [],
  groupQuestionIds: {},
  interviewsCount: {
    total: null,
    completed: null,
    pending: null,
  },
  selectedFeedbackDetails: {
    userId: null,
    name: "",
    slotDetails: {
      slotId: "",
      startDate: "",
      endDate: "",
    },
    image: {
      path: null,
      bucket: null,
    },
    stage: "",
    mentorDetails: [],
    portfolioDetails: {
      RESUME: {
        s3Location: {
          path: null,
          bucket: null,
        },
      },
      PORTFOLIO: {
        s3Location: {
          path: null,
          bucket: null,
        },
      },
    },
  },
  feedbackStep: 0,
  sections: [],
  mentorInsideScreenStatus: "",
  mentorEmptyScreenStatus: "",
  totalSections: 0,
  selectedInterviewList: EvaluationListStatus.PENDING,
};

const evaluationDaySlice = createSlice({
  name: "evaluationDaySlice",
  initialState,
  reducers: {
    setFeedbackQuizData(state, action) {
      state.feedbackQuizData = action.payload;
    },
    setUpdatedFeedbackQuizData(state, action) {
      const { questionId, response, questionType, section } = action?.payload;
      if (questionId) {
        if (questionType === FeedbackQuestionsType.SUBJECTIVE) {
          state.feedbackQuizData[section][questionId].responseVal = response;
        } else {
          state.feedbackQuizData[section][questionId].responseId = response;
        }
        state.feedbackQuizData[section][questionId].type = questionType;
      }
    },
    setGroupQuestionIds(state, action) {
      state.groupQuestionIds = action.payload;
    },
    setSelectedInterviewList(state, action) {
      state.selectedInterviewList = action.payload;
    },
    setSlotsData(state, action) {
      state.slotsData = action.payload;
    },
    setSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
    setSelectedSlot(state, action) {
      state.selectedSlot = action.payload;
    },
    setSlotScreenStatus(state, action) {
      state.slotScreenStatus = action.payload;
    },
    setConfirmPopupScreenStatus(state, action) {
      state.confirmPopupScreenStatus = action.payload;
    },
    setMentorInsideScreenStatus(state, action) {
      state.mentorInsideScreenStatus = action.payload;
    },
    setMentorEmptyScreenStatus(state, action) {
      state.mentorEmptyScreenStatus = action.payload;
    },
    setSelectedFeedbackDetails(state, action) {
      state.selectedFeedbackDetails = action.payload;
    },
    setInterviewsCount(state, action) {
      state.interviewsCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      getCourseContent.endpoints.getEvaluationDayContent.matchFulfilled,
      (state, action) => {
        const role = action?.meta?.arg?.originalArgs?.role;
        getConditionalData(action.payload, API_TYPE.NODE_API, state, role);
      }
    ),
    builder.addMatcher(
      getCourseContent.endpoints.getNodesState.matchFulfilled,
      (state, action) => {
        getConditionalData(action.payload, API_TYPE.STATES_API, state);
      }
    );
  },
});

export const {
  setSlotsData,
  setSelectedTab,
  setSelectedSlot,
  setSlotScreenStatus,
  setConfirmPopupScreenStatus,
  setMentorInsideScreenStatus,
  setMentorEmptyScreenStatus,
  setSelectedFeedbackDetails,
  setSelectedInterviewList,
  setInterviewsCount,
  setFeedbackQuizData,
  setUpdatedFeedbackQuizData,
  setGroupQuestionIds,
} = evaluationDaySlice.actions;
export default evaluationDaySlice.reducer;
