import axios from "axios";
import Cookies from "js-cookie";

import { OnboardingValues } from "@/components/CareerBuilder/CareerNavigatorDesktop/OnboardingQuiz";

import { OneistoxAPILMS } from "./lmsUploadFileApiClient";
import { OneistoxAPIOnBoarding } from "./OnBoardingClient";
import { OneistoxAPIProfile } from "./profileApiClient";

const OneistoxAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_PROXY_2,
});
OneistoxAPI.defaults.headers.common["Content-Type"] = "application/json";
OneistoxAPI.defaults.headers.common["x-auth-token"] = Cookies.get("token");

if (process.env.NEXT_PUBLIC_ENABLE_OVERRIDE_USER) {
  OneistoxAPI.defaults.headers.common["x-auth-override"] =
    process.env.NEXT_PUBLIC_ENABLE_OVERRIDE_USER;
}

type HubspotLeadPayload = {
  data: any;
  emailId: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  fbclid?: string;
  _fbp?: string;
  _fbc?: string;
};

export const createHubspotLead = async (data: any, emailId: string) => {
  // Retrieve URL parameters
  const myKeysValues = window.location;
  const searchResult = myKeysValues.search;
  const urlParams = new URLSearchParams(searchResult);

  // Extract specific URL parameters
  const utm_source = urlParams.get("utm_source");
  const utm_medium = urlParams.get("utm_medium");
  const utm_campaign = urlParams.get("utm_campaign");
  const utm_content = urlParams.get("utm_content");
  const fbclid = urlParams.get("fbclid");

  // created function to extract a cookie value by name
  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(";").shift();
  };

  // Extract _fbp and _fbc from cookies
  const _fbp = getCookie("_fbp");
  const _fbc = getCookie("_fbc");

  // Initialized payload with the defined type
  let payload: HubspotLeadPayload = {
    data,
    emailId,
    fbclid,
    _fbp,
    _fbc,
  };

  // Conditionally add UTM parameters if utm_source is 'facebook'
  if (utm_source === "facebook") {
    payload = {
      ...payload,
      utm_medium,
      utm_campaign,
      utm_content,
    };
  }
  // Call the API with the constructed payload
  return await OneistoxAPI.post(`v1/client/hubspot/lead`, payload);
};

export const inviteReferral = async (data: any) => {
  return await OneistoxAPI.post(`v1/client/invite-referral/send-confirmation-referrer`, data);
};
export const sendgridLeadEBook = async (data: any) => {
  return await OneistoxAPI.post(`v1/client/sendgrid/lead/ebook`, { data });
};

export const sendgridLeadCourse = async (data: any) => {
  return await OneistoxAPI.post(`v1/client/sendgrid/lead/course`, {
    data,
  });
};

export const supportMail = async (data: any) => {
  return await OneistoxAPI.post(`v1/client/email/contact-us`, { data });
};

export const setAuthToken = (token: string) => {
  Cookies.set("token", token, { expires: 3 });
  Cookies.set("loggenIn", "true", { expires: 3 });
  OneistoxAPI.defaults.headers.common["x-auth-token"] = Cookies.get("token");
  OneistoxAPILMS.defaults.headers.common["x-auth-token"] = Cookies.get("token");
  OneistoxAPIOnBoarding.defaults.headers.common["x-auth-token"] = Cookies.get("token");
  OneistoxAPIProfile.defaults.headers.common["x-auth-token"] = Cookies.get("token");
};

export const removeAuthToken = () => {
  OneistoxAPI.defaults.headers.common["x-auth-token"] && logout().catch((e) => console.error(e));
  Cookies.remove("token");
  Cookies.remove("loggenIn");
  if (window?.posthog) {
    window.posthog.reset();
  }
  OneistoxAPI.defaults.headers.common["x-auth-token"] = null;
  OneistoxAPILMS.defaults.headers.common["x-auth-token"] = null;
  OneistoxAPIOnBoarding.defaults.headers.common["x-auth-token"] = null;
  OneistoxAPIProfile.defaults.headers.common["x-auth-token"] = null;
};

export const createSubscription = async (token) => {
  return OneistoxAPI.post(`v1/client/public/oms/create-stripe-subsciption?token=${token}`);
};

export const fetchPaymentPlan = async (token: string) => {
  return OneistoxAPI.get(
    `v1/client/public/oms/stripe-subscription-payment-plan-details?token=${token}`
  );
};

export const sendAnalytics = async (
  productId: string,
  event: string,
  subEvent: string,
  meta: any,
  payload: any
) => {
  if (meta) {
    meta.eventTimestamp = new Date();
  } else {
    meta = { eventTimestamp: new Date() };
  }
  return await OneistoxAPI.post(
    `v1/client/analytics/event/lms/${event}`,
    { subEvent, meta, payload },
    {
      headers: { "x-product-id": productId },
    }
  );
};

export const emailSignUp = async (
  emailId: string,
  password: string,
  firstName: string,
  lastName: string
) => {
  return await OneistoxAPI.post(`v1/client/signup/email`, {
    emailId,
    password,
    firstName,
    lastName,
  });
};

export const socialSignUp = async (method: string, tokenId: string) => {
  return await OneistoxAPI.post(`v1/client/signup/social`, { method, tokenId });
};

export const socialLogin = async (method: string, tokenId: string) => {
  return await OneistoxAPI.post(`v1/client/login/social`, { method, tokenId });
};

export const emailLogin = async (emailId: string, password: string) => {
  return OneistoxAPI.post(`v1/client/login/email`, { emailId, password });
};

export const emailVerification = async (encodedEmailId: string, token: string) => {
  return await OneistoxAPI.get(
    `v1/client/login/emailVerification/${encodedEmailId}?token=${token}`
  );
};

export const getProfileStatus = async () => {
  return await OneistoxAPI.put(`v1/client/user/profile/status`, {
    bypass: true,
  });
};

export const updateUserDataClient = async (phone: string) => {
  return await OneistoxAPI.put(`v1/client/user/data`, { phone });
};

export const passwordReset = async (emailId: string) => {
  return await OneistoxAPI.post(`v1/client/login/passwordReset`, { emailId });
};

export const passwordResetVerification = async (newPassword: string, verificationToken: string) => {
  return await OneistoxAPI.post("v1/client/login/passwordResetVerification", {
    newPassword,
    verificationToken,
  });
};

export const sendEmailVerification = async () => {
  return await OneistoxAPI.post(`v1/client/user/sendEmailVerification`);
};

export const passwordChange = async (oldPassword: string, newPassword: string) => {
  return await OneistoxAPI.post(`v1/client/user/passwordChange`, {
    oldPassword,
    newPassword,
  });
};

export const uploadProfileProjectDocument = async (data) => {
  return await OneistoxAPI.put(`v1/client/user/profile/project-document`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadUserProfileDocument = async (data, uploadType) => {
  return await OneistoxAPI.put(`v2/client/user/profile/document/${uploadType}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadProfilePhoto = async (data) => {
  return await OneistoxAPI.put(`v1/client/user/profile/photo`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const logout = async (): Promise<void> => {
  return await OneistoxAPI.post(`v1/client/user/logout`);
};

export const getUserData = async () => {
  return await OneistoxAPI.get(`v1/client/user/data`);
};

export const getUserDataWithUserProfile = async (): Promise<{
  data: { userData: any; userProfile: any };
}> => {
  return await OneistoxAPI.get(`v1/client/user/data-profile`);
};

export const getUserProfile = async () => {
  return await OneistoxAPI.get(`v1/client/user/profile`);
};

export const getProductPricing = async (
  productId: string,
  pricingStage: string,
  currency: string,
  voucherCode?: string,
  emailId?: string,
  billingContact?: any,
  billingAddress?: any
) => {
  return OneistoxAPI.post(`v1/client/public/oms/pricing/${productId}`, {
    pricingStage,
    productId,
    currency,
    voucherCode,
    emailId,
    billingContact,
    billingAddress,
  });
};

export const isVoucherApplicable = async (
  productId: string,
  pricingStage: string,
  currency: string,
  voucherCode: string,
  emailId: string
) => {
  return OneistoxAPI.post(`v1/client/public/oms/voucher/applicable/${productId}`, {
    pricingStage,
    productId,
    currency,
    voucherCode,
    emailId,
  });
};

export const createStripeOrder = async (options: any) => {
  return OneistoxAPI.post(`v1/client/public/oms/order/stripe`, options);
};

export const createOrder = async (options: any) => {
  return OneistoxAPI.post(`v2/client/public/oms/order`, options);
};

export const getPublicCohort = async (productId: any) => {
  return OneistoxAPI.get(`v1/client/public/lms/cohort/basic/${productId}`);
};

export const getBlogsData = async () => {
  return axios.get(`${process.env.NEXT_PUBLIC_ASSETS_2}/client/blogs/blogs.json`);
};

export const getTagsData = async () => {
  return axios.get(`${process.env.NEXT_PUBLIC_PROXY_2}/v1/client/public/lms/cohorts/new-lms3`);
};

export const getBlogData = async (url: string) => {
  return axios.get(`${process.env.NEXT_PUBLIC_ASSETS_2}/client/blogs/${url}.json`);
};

export const createFristPaymentPreview = async (token: string) => {
  return OneistoxAPI.post(`v1/client/public/oms/order/payment-plan-review`, {
    token,
  });
};

export const uploadMentorLeadDocument = async (data: any) => {
  return await OneistoxAPI.put(`v1/client/public/lms/mentor-leads/docs`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// career builder

export const getCareerBuilderForm = async (formId: any) => {
  return OneistoxAPI.get(`v1/client/public/career-builder/career-builder-form/${formId}`);
};

export const submitCareerBuilderForm = async (
  email: string,
  data: any,
  pathId: "CAREER_BUILDER_CE" | "CAREER_BUILDER",
  formId: "CAREER_BUILDER_QUIZ_CE" | "CAREER_BUILDER_QUIZ"
) => {
  return OneistoxAPI.put(
    `v2/client/public/career-builder/submit-quiz-response?emailId=${email}&careerType=${pathId}&formId=${formId}`,
    data
  );
};

export const getDesignations = async () => {
  return OneistoxAPI.get(`v1/client/public/career-builder/onboarding-career-roles`);
};
export const submitOnboardingForm = async (data: OnboardingValues, formId: any) => {
  return OneistoxAPI.post(`v2/client/public/career-builder/lead-user-data?formId=${formId}`, data);
};

export const updateLeadUserEmail = async (oldEmail: string, leadUserEmail: string) => {
  return OneistoxAPI.put(
    `v2/client/public/career-builder/update-lead-user-data?email=${oldEmail}`,
    { leadUserEmail: leadUserEmail }
  );
};

export const getDataFromToken = async (token: any) => {
  return OneistoxAPI.get(`v1/client/public/career-builder/report-details?token=${token}&pathId`);
};

export const sendResultEmail = async (
  oldEmail: string,
  body: { pathId: any; leadUserEmail: string }
) => {
  return OneistoxAPI.post(`v1/client/public/career-builder/result-email?email=${oldEmail}`, body);
};

export const getFeedback = async (oldEmail: string, rating: number, formId: string) => {
  return OneistoxAPI.post(
    `v1/client/public/career-builder/feedback?email=${oldEmail}&formId=${formId}`,
    { rating: rating }
  );
};

export const updateUserPhonePopup = async (
  oldEmail: string,
  phone: { leadUserPhone: string },
  popupType: string
) => {
  return OneistoxAPI.put(
    `v2/client/public/career-builder/update-lead-user-data/${popupType}?email=${oldEmail}`,
    phone
  );
};

// lead nurturing update
export const updateUserDetails = async (
  oldEmail: string,
  userDetails: {
    leadUserEmail: string;
    leadUserPhone: string;
    leadfullName: string;
    workExperience: string;
  },
  popupType: string
) => {
  return OneistoxAPI.put(
    `v2/client/public/career-builder/update-lead-user-data/${popupType}?email=${oldEmail}`,
    userDetails
  );
};
// lead nurturing post
export const registerPortfolioUpgrade = async (
  emailId: string,
  userDetails: {
    leadUserEmail: string;
    leadUserPhone: string;
    leadfullName: string;
    bachelorsDegree: string;
  }
) => {
  try {
    const response = await OneistoxAPI.post(
      `v1/client/public/career-builder/register-for-portfolio-upgrade?emailId=${emailId}`,
      userDetails
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateUserEmail = async (
  oldEmail: string,
  email: { leadUserEmail: string },
  popupType: string
) => {
  return OneistoxAPI.put(
    `v2/client/public/career-builder/update-lead-user-data/${popupType}?email=${oldEmail}`,
    email
  );
};

export const findingMatches = async (careerRoleId: any) => {
  return OneistoxAPI.get(
    `/v1/client/public/career-builder/matching-career-roles?careerRoleId=${careerRoleId}`
  );
};

export const careerBuilderResult = async (emailId: any) => {
  return OneistoxAPI.get(`/v2/client/public/career-builder/result?emailId=${emailId}`);
};
// LMS

export const getPublicSkillsList = async (cohortId: string) => {
  return OneistoxAPI.get(`v1/client/public/lms/skills?cohort-id=${cohortId}`);
};

export const getFinalScore = async (emailId: any) => {
  return OneistoxAPI.get(`v1/client/public/career-builder/score?emailId=${emailId}`);
};

export const getPerformance = async (cohortId: any, userId) => {
  return OneistoxAPI.post(
    `v2/client/lms/${cohortId}/performance?userId=${userId}`
    // '/v2/client/lms/BIM_A_011/performance?userId=6253'
  );
};

// MMS
export const getImpactData = () => {
  return OneistoxAPI.get(`v1/client/mentor/lm/impact`);
};
export const getImpactLeaderBoard = () => {
  return OneistoxAPI.get(`v1/client/mentor/lm/top-mentors-by-category`);
};

export const getColleges = async (searchQuery: string) => {
  return await axios.get(`http://universities.hipolabs.com/search`, {
    params: { name: searchQuery },
  });
};

// Referral
export const getRefereeData = (refereeId: string) => {
  return OneistoxAPI.get(
    `v1/client/invite-referral/referrer-details?uniqueReferrerId=${refereeId}`
  );
};

export const getHallOfFame = () => {
  return OneistoxAPI.get(`v1/client/user-referral/hall-of-fame`);
};

export const getRewardsData = () => {
  return OneistoxAPI.get(`/v1/client/invite-referral/fetch-referer-earning`);
};

export const getReferralLink = () => {
  return OneistoxAPI.get(`/v1/client/user-referral/referral-link`);
};

export const getReferralCount = () => {
  return OneistoxAPI.get(`/v1/client/invite-referral/fetch-paid-referees-count`);
};

export const getReferredData = (offset: number, limit: number) => {
  return OneistoxAPI.get(
    `/v1/client/invite-referral/fetch-referees?offset=${offset}&limit=${limit}`
  );
};

export const getWelcomeScreenStatus = () => {
  return OneistoxAPI.get(`/v1/client/invite-referral/fetch-referral-visit-status`);
};

export const getUserLocation = () => {
  return OneistoxAPI.get("/v1/client/public/lms/location");
};
