import { useRouter } from "next/router";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { trackleadform } from "@/analytics/analytics";

import Box from "@/design-system/Box";
import Modal from "@/design-system/Modal";
import useMediaQuery from "@/design-system/useMediaQuery";

import { useAppSelector } from "@/hooks/hooks";
import { useScreenTime } from "@/hooks/useScreenTime";

import { routerPage } from "@/utils/utils";

import { theme } from "@/theme";

import {
  binaryEmojiMapping,
  formFooter,
  formHeader,
  formSubmitContent,
  numberEmojiMapping,
  getCommonEventProperties,
} from "./constant";
import Feedbackform from "./Form/FeedbackForm";
import { FEEDBACK_QUESTION_TYPE } from "./type";
interface formModalType {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  data: any;
  saveForm: any;
  previousRoute: string;
  cohortId: string;
}
export default function FormModal({
  isOpen,
  setIsOpen,
  data,
  saveForm,
  previousRoute,
  cohortId,
}: formModalType) {
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  const refVal = useRef(true);
  const time = useScreenTime();
  const [selectQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const router = useRouter();
  const routePageName = routerPage(router.pathname);

  const mixpanelObj = useMemo(() => {
    if (typeof window !== "undefined" && loginDetails && data) {
      return getCommonEventProperties(
        loginDetails,
        data,
        cohortId,
        previousRoute,
        router?.pathname
      );
    }
  }, [loginDetails, data, previousRoute]);

  const handleClose = (btnText?: string) => {
    const modifiedTRackObj = {
      ...mixpanelObj,
      Button_text: btnText,
    };
    trackleadform("Button_Click", modifiedTRackObj);

    if (selectQuestionIndex !== updatedFormData?.questions?.length) {
      trackleadform("Form_abondonment", modifiedTRackObj);
      saveForm({
        page: routePageName,
        cohortId: cohortId,
        isSkipped: true,
        notificationId: data?.notificationId,
      });
    }
    setIsOpen(false);
  };

  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.md})`);
  const updatedFormData = useMemo(() => {
    const formData = data?.form ? JSON.parse(JSON.stringify(data?.form)) : {};
    if (formData?.questions?.length > 1) {
      formData.header = JSON.parse(JSON.stringify(formHeader));
      formData.footer = JSON.parse(JSON.stringify(formFooter));
    }
    formData.freezeAtLastAttempt = data?.meta?.freezeAtLastAttempt || false;
    formData.submitContent = JSON.parse(JSON.stringify(formSubmitContent));
    if (data?.meta?.freezeAtLastAttempt && formData.footer) {
      formData.footer.components.length = 0;
    }
    formData.questions = data?.form?.questions?.map((question: any) => {
      if (
        question.questionType === FEEDBACK_QUESTION_TYPE.MULTI_CHOICE ||
        question.questionType === FEEDBACK_QUESTION_TYPE.SINGLE_CHOICE
      ) {
        return {
          ...question,
          options: question.options.map((option) => {
            return {
              ...option,
              isActive: false,
            };
          }),
        };
      }
      if (question.questionType === FEEDBACK_QUESTION_TYPE.SCALE_EMOJI) {
        return {
          ...question,
          options: question.options.map((option, index: number) => {
            return {
              ...option,
              icon: numberEmojiMapping[index + 1],
            };
          }),
        };
      }
      if (
        question.questionType === FEEDBACK_QUESTION_TYPE.BINARY_LIKE_DISLIKE ||
        question.questionType === FEEDBACK_QUESTION_TYPE.BINARY_TRUE_FALSE ||
        question.questionType === FEEDBACK_QUESTION_TYPE.BINARY_YES_NO
      ) {
        return {
          ...question,
          options: question.options.map((option, index: number) => {
            return {
              ...option,
              icon: binaryEmojiMapping[index + 1],
            };
          }),
        };
      }
      return question;
    });
    return formData;
  }, [data]);

  useEffect(() => {
    if (refVal.current && mixpanelObj && updatedFormData && isOpen) {
      const length = updatedFormData?.questions?.[0]?.options?.length;
      const newMixpanelTrackObj = {
        ...mixpanelObj,
        question_content: updatedFormData?.questions?.[0]?.question ?? null,
        question_tag: updatedFormData?.questions?.[0]?.meta.questionTag ?? null,
        category: updatedFormData?.questions?.[0]?.meta.category ?? null,
        sub_category: updatedFormData?.questions?.[0]?.meta.subCategory ?? null,
        min_score: length > 0 ? updatedFormData?.questions?.[0]?.options?.[0].optionId : null,
        max_score:
          length > 0 ? updatedFormData?.questions?.[0]?.options?.[length - 1].optionId : null,
        on_screen_duration: time.getFormattedScreenTime(),
      };
      trackleadform("Form_view", newMixpanelTrackObj);
      refVal.current = false;
    }
  }, [mixpanelObj, updatedFormData, refVal.current, isOpen]);

  const formModalType = useMemo(() => {
    if (updatedFormData?.questions && updatedFormData?.questions?.length > 1) {
      return "center";
    }
    return "bottom";
  }, [updatedFormData]);

  return (
    <Modal modalIsOpen={isOpen}>
      <Box
        display={"flex"}
        justifyContent={formModalType === "bottom" ? "end" : "center"}
        alignItems={formModalType === "bottom" ? "end" : "center"}
        height={"100%"}
        width={"100%"}
        margin={formModalType === "bottom" ? "0px 76px 76px 0px" : isMobile ? "16px" : "0px"}
      >
        {data?.form && (
          <Feedbackform
            selectQuestionIndex={selectQuestionIndex}
            setSelectedQuestionIndex={setSelectedQuestionIndex}
            saveForm={saveForm}
            notificationId={data?.notificationId}
            formJson={updatedFormData}
            isOpen={isOpen}
            handleClose={handleClose}
            isBottomContainer={data?.form.questions.length === 1}
            mixpanelObj={mixpanelObj}
            cohortId={cohortId}
          />
        )}
      </Box>
    </Modal>
  );
}
