import dayjs from "dayjs";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import _ from "lodash";
import { ChangeEvent } from "react";

import { IconButtonProps } from "@/design-system-v2/IconButton";

import { PrimaryButtonProps } from "@/design-system-v3/Button/PrimaryButton/types";

import { FeedbackReportResponse, FeedbackReportTransform } from "@/client/types/evalutaionApi.type";
import { steps } from "@/components/LMSV3/EvaluationDay/constant";

import { theme } from "@/theme";

import {
  API_TYPE,
  FeedbackQuestionsType,
  META_CONTENT_TYPE,
  NAMESPACE_TYPE,
  NODE_ENTRY_TYPE,
  TopicCategory,
} from "./types.enums";

export const getMoneyString = (price: number, currency: string) => {
  const countryLang = currency === "INR" ? "en-IN" : currency === "USD" ? "en-US" : "";

  const formatter = new Intl.NumberFormat(countryLang, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,

    //These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return `${formatter.format(price / 100)}`;
};

export const truncate = (input: string, maxLength: number) => {
  return input && input.length > maxLength ? `${input.substring(0, maxLength).trim()}...` : input;
};

export const isInViewport = (el: { getBoundingClientRect }) => {
  const rect = el?.getBoundingClientRect();
  return (
    rect?.top >= 0 &&
    rect?.left >= 0 &&
    rect?.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect?.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const debounceFunction = function (
  func: { (event: ChangeEvent<HTMLInputElement>): void; apply },
  delay: number
) {
  let timer: ReturnType<typeof setTimeout>;

  return function (this, ...args) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

export const formatTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(Number(seconds) % 60);
  return `${
    hours
      ? `${hours}hr:${minutes}min`
      : `${minutes}min :${remainingSeconds < 10 ? "0" : ""}${remainingSeconds} sec`
  }`;
};

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

export const convertMsToTime = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  return {
    hours: padTo2Digits(hours),
    minutes: padTo2Digits(minutes),
    seconds: padTo2Digits(seconds),
  };
};

export const courseUrl = (productType, productId, tags, onboarding) => {
  if (tags?.includes("new-lms3")) {
    if (onboarding) {
      return `/onboarding?cohortId=${productId}`;
    } else {
      return `/learn/${productId}/dashboard`;
    }
  }
  if (tags?.includes("new-lms2")) {
    return `/course?cohort-id=${productId}`;
  }
  switch (productType) {
  case "COHORT":
    return `/courses/cohort?id=${productId}`;
  default:
    return `/dashboard/onlineWorkshop?id=${productId}`;
  }
};

export const mentorUrl = (isUserIg, productId, tags, onboarding) => {
  if (tags?.includes("new-lms3")) {
    if (onboarding) {
      return `/onboarding?cohortId=${productId}`;
    } else {
      return isUserIg ? `/mentor/${productId}/dashboard` : `/lm/${productId}/dashboard`;
    }
  }
  return `/dashboard/mentor`;
};

export const getLastMonthsOptions = (monthCount = 14) => {
  const d = new Date();
  d.setDate(1);
  const dateList = [];
  for (let i = 0; i < monthCount; i++) {
    const option = {
      title: dayjs(d).format("MMM YYYY"),
      value: dayjs(d).format("YYYY-MM-DD"),
    };
    dateList.push(option);
    d.setMonth(d.getMonth() - 1);
  }
  return dateList;
};

export const getScrollPositionByPercentage = () => {
  let percentage = 0;
  if (typeof document !== "undefined") {
    const h = document?.documentElement,
      b = document?.body,
      st = "scrollTop",
      sh = "scrollHeight";
    percentage = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
    if (percentage < 10) {
      percentage = 0;
    } else if (percentage >= 10 && percentage < 25) {
      percentage = 10;
    } else if (percentage >= 25 && percentage < 50) {
      percentage = 25;
    } else if (percentage >= 50 && percentage < 75) {
      percentage = 50;
    } else if (percentage >= 75 && percentage < 90) {
      percentage = 75;
    } else if (percentage >= 90 && percentage < 100) {
      percentage = 90;
    } else percentage = 100;
  }
  return percentage;
};

export const getFilteredList = (childVector: { [key: string]: any }[], type: string) => {
  const filteredData = childVector.filter((element) => element.type === type);
  return filteredData;
};

export const getSelectedCards = (childZone: any) => {
  return childZone?.map((element) => {
    const { meta, children = [], parentNodeId, nodeId } = element || {};
    const { zoneTitle, zoneName, zoneDescription } = meta || {};
    const zoneMetas = children?.filter((element) => element?.type === "META")?.[0] || {};
    const zoneFeaturesInfo =
      zoneMetas?.children?.filter((element) => element?.meta?.key === "keyValues")?.[0] || {};
    const zoneImagesInfo =
      zoneMetas?.children?.filter((element) => element?.meta?.key === "images")?.[0] || {};
    const zoneFeatures = zoneFeaturesInfo?.children?.map((element) => {
      return {
        heading: element.meta.key,
        value: element.meta.value,
      };
    });
    const zoneImages = zoneImagesInfo?.children?.map((element) => {
      return {
        meta: { ...element.meta },
      };
    });
    return {
      zoneName,
      zoneTitle,
      zoneDescription,
      zoneFeatures,
      images: { meta: { content: "Examples here :" }, children: zoneImages },
      parentNodeId,
      nodeId,
    };
  });
};

export const getSplittedCode = (data, splitChar, index) => {
  const midData = data?.split(splitChar);
  if (index > midData?.length) return "";
  if (index < 0) return midData?.[midData?.length + index];
  return data?.split(splitChar)?.[index];
};

export const getDateByType = (arr, dateType) => {
  const dateObject = arr?.find((t) => t.key === dateType);
  return dateObject ? dateObject?.meta?.time : "";
};

export const getStartOfDay = (date) => {
  return dayjs(date)
    .startOf("day")
    .weekday(1)
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .format();
};

export const getEndOfDayOfWeek = (date) => {
  return dayjs(date).endOf("day").weekday(7).format();
};

export const getProjectKeysWithCredit = (project: any) => {
  const metaProperties = project?.children?.filter((element) => element?.type === "META")?.[0];
  const projectFeatures =
    metaProperties?.children?.filter((element) => element?.meta?.key == "keyValues")?.[0]
      ?.children || [];

  const projectFeatureData = projectFeatures?.map((element) => {
    return {
      heading: element?.meta?.key,
      value: element?.meta?.value,
    };
  });
  const creditInfo = projectFeatureData.find((element: { [key: string]: string }) =>
    element?.heading?.toLowerCase()?.includes("credits")
  );
  return creditInfo;
};

export function intToChar(num: number): string {
  //  for Uppercase letters, replace `a` with `A`
  const code = "A".charCodeAt(0);

  return String.fromCharCode(code + num);
}

export function getDigitsFromString(str: string): number {
  return parseInt(str.match(/\d/g).join(""));
}

export const compareNodeIds = function (a: string, b: string) {
  // Extract the numeric part from the strings (excluding the "M")
  const indexNumberA: string = _.last(a.split("_")) || "";
  const indexNumberB: string = _.last(b.split("_")) || "";
  const numA = parseInt(indexNumberA.match(/\d+/)?.[0] || "", 10);
  const numB = parseInt(indexNumberB.match(/\d+/)?.[0] || "", 10);
  return numA - numB;
};

export function sortContentByRank(arr = []) {
  return [...arr].sort((a: any, b: any) => {
    const aRank = parseInt(a.meta.rank);
    const bRank = parseInt(b.meta.rank);
    let result = aRank - bRank;
    if (result == 0 && (aRank || bRank)) result = compareNodeIds(a?.nodeId, b?.nodeId);
    return result;
  });
}

export const formatTimeAgo = (date) => {
  const now = dayjs();
  const diffInSeconds = Math.floor(Math.abs(now.diff(date, "seconds")));

  if (diffInSeconds < 60) {
    return `${diffInSeconds}s ago`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes}m ago`;
  } else {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours}h ago`;
  }
};

export function formatDate(date) {
  return dayjs(date).format("DD MMM");
}

export const namespaceMapping = {
  [NAMESPACE_TYPE.LEARNING]: { namespaceText: "Core", namespaceColor: theme.colors.grayCool[700] },
  [NAMESPACE_TYPE.CAREER]: { namespaceText: "Career Hub", namespaceColor: theme.colors.teal[700] },
  [NAMESPACE_TYPE.CAPSTONE]: {
    namespaceText: "Capstone",
    namespaceColor: theme.colors.blueLight[600],
  },
};

export const getEstimatedTime = (time) => {
  const hours = Math.floor(time / 60);
  const minutes = time % 60;
  return `${hours ? hours + " hrs" : ""} ${minutes ? minutes + " min" : ""} `;
};

export const isDateBetween = (startDate, endDate) => {
  return (
    new Date(startDate).getTime() <= new Date().getTime() &&
    new Date().getTime() <= new Date(endDate).getTime()
  );
};

export const routerPage = (pathname: string) => {
  const splitPathname = pathname.split("/");
  const lastSplitElement = splitPathname[splitPathname.length - 1];
  const pageMap = {
    dashboard: "DASHBOARD",
    "ig-hub": "IG_HUB",
    "placement-hub": "PLACEMENT_HOME",
    curriculum: "CURRICULUM",
    capstone: "CAPSTONE_HUB",
    queries: "QUERIES",
    "task-assessment": "ASSESSMENTS",
    "skill-progress": "CREDITS",
    tasks: "ASSESSMENTS",
    earnings: "EARNINGS",
    "my-performance": "IG_PERFORMANCE",
    "group-performance": "GROUP_PERFORMANCE",
    "learner-hub": "LEARNER_HUB",
  };
  return pageMap[lastSplitElement];
};

export const validatePhoneNumber = (value: string, defaultCountryCode?: CountryCode): boolean => {
  if (value?.length < 6) return false;
  const phoneNumber = parsePhoneNumber(value!, defaultCountryCode);
  if (phoneNumber.isValid()) return true;
  return false;
};

export const isDayBeforeStartDate = (nodeEntries: any[], role: string): boolean => {
  if (role === "LEARNER") {
    return nodeEntries?.every((nodeEntry) => {
      const currentDate = new Date();
      const startDate = new Date(nodeEntry.meta.startDate);
      return currentDate < startDate;
    });
  }

  if (role === "INDUSTRIAL_GUIDE") {
    const nodeEntry = nodeEntries?.find((e) => e.meta.topicCategory === "EVALUATION_DAY");
    const currentDate = new Date();
    const startDate = new Date(nodeEntry?.meta?.startDate);
    return currentDate < startDate;
  }
};

export const getEvaluationDayEndDate = (nodeEntries: any[]) => {
  const nodeEntry = nodeEntries?.find((e) => e.meta.topicCategory === "EVALUATION_DAY");
  return nodeEntry?.meta?.endDate;
};

export const getModifyData = (keys: Array<META_CONTENT_TYPE>, staticContentData: any) => {
  const staticKeyValueObj = {};
  keys.forEach((key: META_CONTENT_TYPE) => {
    const staticData = staticContentData[key];
    switch (key) {
    case META_CONTENT_TYPE.MAIN_CONTENT: {
      const title = staticData?.meta?.title;
      const metaArray = staticData?.children
        ?.filter((child) => child.type === NODE_ENTRY_TYPE.PARAGRAPH)
        .map((child) => {
          return {
            ques: child.meta.heading,
            ans: child.meta.content,
          };
        });
      staticKeyValueObj[key] = {
        title,
        list: metaArray,
      };
      break;
    }
    case META_CONTENT_TYPE.FAQ: {
      const title = staticData?.meta?.title;
      const faqArray = staticData?.children.map((child) => {
        return {
          question: child.meta.heading,
          answer: child.meta.content,
        };
      });
      staticKeyValueObj[key] = {
        list: faqArray,
        title,
      };
      break;
    }

    case META_CONTENT_TYPE.HELPFUL_RESOURCE: {
      const title = staticData?.meta?.title;
      const list = staticData?.children?.find((child) => child.type === NODE_ENTRY_TYPE.LIST);
      const helpfulResourceArray = list?.children.map((child) => {
        return {
          name: child.meta.referenceLabel,
          link: child.meta.referenceLink,
        };
      });
      staticKeyValueObj[key] = {
        list: helpfulResourceArray,
        title,
      };
      break;
    }
    }
  });
  return staticKeyValueObj;
};

export const getTransformedReport = (response: FeedbackReportResponse): FeedbackReportTransform => {
  const {
    interviewDetails: {
      interviewResponse: { sections: sectionsData },
    },
  } = response;
  const sectionsModified = [];
  for (const section of sectionsData) {
    const sectionObj = {
      sectionTitle: section?.meta?.sectionTitle,
      quesAndResData: [],
    };
    const questions = section.questions;
    for (const question of questions) {
      if (question.questionType === FeedbackQuestionsType.GROUPED_OPTION_QUESTION) {
        const tableData = [];
        for (const gSubQues of question.questions) {
          const responseVal = question.options.find(
            (e) => e.optionId === gSubQues?.meta?.response?.entries[0]?.optionId
          )?.value?.value;
          const [ansVal, description] = responseVal ? responseVal.split(/(?=\s\()/) : ["", ""];
          tableData.push({
            ques: gSubQues?.question,
            ans: ansVal,
            desc: description,
          });
        }
        sectionObj.quesAndResData.push({
          ques: question?.question,
          desc: question?.description,
          type: "group",
          resp: tableData,
        });
      } else {
        let responseVal;
        if (question.questionType === FeedbackQuestionsType.SUBJECTIVE) {
          responseVal = question?.meta?.response?.entries[0]?.optionValue;
        } else {
          responseVal = question.options.find(
            (e) => e.optionId === question?.meta?.response?.entries[0]?.optionId
          )?.value?.value;
        }
        sectionObj.quesAndResData.push({
          ques: question?.question,
          desc: question?.description,
          type: "text",
          resp: responseVal,
        });
      }
    }
    sectionsModified.push(sectionObj);
  }
  response.interviewDetails.interviewResponse.sections = sectionsModified;
  return response as any as FeedbackReportTransform;
};

export const getConditionalData = (data, type, state, role = "LEARNER") => {
  if (type === API_TYPE.STATES_API) {
    state.stateData = data;
    return;
  }

  const metaContent = data?.children?.find((child) => child.type === NODE_ENTRY_TYPE.META);
  const readStaticContent = metaContent?.children?.find(
    (child) => child.type === NODE_ENTRY_TYPE.READ_STATIC
  );
  let quizData = null;

  state.lmsNodeId = data?.children?.find(
    (child) => child.meta.topicCategory === "EVALUATION_DAY"
  )?.childrenNodeIds[0];

  let liveSessionNode = null;
  const topicEntries = data?.children?.filter((child) => {
    if (child.meta.topicCategory === TopicCategory.EVALUATION_DAY) {
      quizData = child.children.find((element) => element.type === "QUIZ");
      liveSessionNode = child.children.find((element) => element.type === "LIVE_SESSION");
    }
    return child.type === NODE_ENTRY_TYPE.TOPIC;
  });

  switch (type) {
  case API_TYPE.NODE_API: {
    const isEvaluationDayTopicDateHaveNotPassed = isDayBeforeStartDate(topicEntries || [], role);
    const evaluationDayEndDate = getEvaluationDayEndDate(topicEntries);
    const evaluationTopicData =
        topicEntries?.find(
          (element) => element.meta.topicCategory === TopicCategory.EVALUATION_DAY
        ) || {};
    let isDetailedReportVisible = false;
    const dateObj = {
      startDate: evaluationTopicData?.meta?.startDate,
      endDate: evaluationTopicData?.meta?.endDate,
    };
    if (dateObj?.endDate) {
      const tempEndDate = new Date(dateObj?.endDate);
      tempEndDate.setHours(tempEndDate.getHours() + 12);
      isDetailedReportVisible = new Date() > tempEndDate;
    }

    const staticContentChildrenObj = {};
    readStaticContent?.children?.forEach((child) => {
      staticContentChildrenObj[child.meta.readType] = child;
    });
    const modifiedContentData = getModifyData(
      [META_CONTENT_TYPE.FAQ, META_CONTENT_TYPE.HELPFUL_RESOURCE, META_CONTENT_TYPE.MAIN_CONTENT],
      staticContentChildrenObj
    );
    state.contentData = {
      modifiedContentData,
      isEvaluationDayTopicDateHaveNotPassed,
      evaluationDayEndDate,
      rootDate: {
        startDate: dayjs(data?.meta?.startDate).format("DD MMM YYYY"),
        endDate: dayjs(data?.meta?.endDate).format("DD MMM YYYY"),
      },
      topicEntries,
      quizData,
      liveSessionNode,
      isDetailedReportVisible,
    };
  }
  }
};

export const getFileLink = (path, bucket) => {
  return `https://${bucket}.s3.ap-south-1.amazonaws.com/${path}`;
};

export const colorMap = {
  COMPLETED: "Completed",
  CREATED: "Pending",
  ONGOING: "Live",
  MISSED: "Missed",
};

// export const formatNextCohortDate = (date?: string): string => {
//   return date ? dayjs(date).format("D MMMM, YYYY") : "Coming Soon";
// };

export const formatNextCohortDate = (date?: string | null): string => {
  if (!date) {
    return "Coming Soon";
  }
  // Regex to check if the date is in the exact ISO 8601 format: YYYY-MM-DDTHH:mm:ss.sssZ
  const iso8601DateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

  if (iso8601DateTimeRegex.test(date) && dayjs(date).isValid()) {
    return dayjs(date).format("D MMMM, YYYY");
  }
  // If not in the ISO 8601 format or parsing fails, return the original string
  return date;
};

export const formatDateForInput = (dateString) => {
  if (!dateString) return "";
  // Assuming dateString is in ISO 8601 format, e.g., "2024-06-17T00:00:00.000Z"
  const date = new Date(dateString);
  // Format date to YYYY-MM-DD
  const formattedDate = date.toISOString().split("T")[0];
  return formattedDate;
};

/**
 * Generates a Cypress data-id attribute for a primary button element.
 *
 * The generated data-id is a string in the format `button_<path>_<label>`, where:
 * - `path`: The last segment of the provided `pathString`.
 * - `label`: The lowercased, hyphenated label of the button, or "unknown" if no label is provided.
 *
 * @param pathString The path string to extract the path segment from.
 * @param props The {@link PrimaryButtonProps} object containing the button's properties.
 * @returns The generated Cypress data-id string.
 */
export const generateCypressDataIdForButton = (pathString: string, props: PrimaryButtonProps) => {
  const path = pathString.split("/").at(-1) || "homepage";

  let label = "unknown";
  try {
    label =
      props.label && typeof props.label === "string"
        ? props.label
          .toLowerCase()
          .replace(/[^a-zA-Z0-9 ]/g, "")
          .replaceAll(" ", "-")
        : "unknown";
  } catch (e) {
    label = "exception_unknown";
  }

  return `button_${path}_${label}`;
};

/**
 * Generates a Cypress data-id attribute for an anchor element.
 *
 * The generated data-id is a string in the format `link_<path>_<label>`, where:
 * - `path`: The last segment of the provided `pathString`.
 * - `label`: The trimmed, lowercased, and hyphenated text content of the anchor element,
 *           or "unknown" if no text content is available.
 *
 * @param pathString The path string to extract the path segment from.
 * @param props The {@link HTMLAnchorElement} object representing the anchor element.
 * @returns The generated Cypress data-id string.
 */
export const generateCypressDataIdForAnchor = (pathString: string, props: HTMLAnchorElement) => {
  const path = pathString.split("/").at(-1) || "homepage";
  let label = "unknown";
  try {
    label =
      props.textContent && typeof props.textContent === "string"
        ? props.textContent
          .trim()
          .toLowerCase()
          .replace(/[^a-zA-Z0-9 ]/g, "")
          .replaceAll(" ", "-")
        : "unknown";
  } catch (e) {
    label = "exception_unknown";
  }

  return `link_${path}_${label}`;
};

/**
 * Generates a Cypress data-id attribute for an icon button.
 *
 * The generated data-id is a string in the format `link_<path>_<icon_type>`, where:
 * - `path`: The last segment of the provided `pathString`.
 * - `label`: The trimmed, lowercased, and icon >> type,
 *           or "unknown" otherwise.
 *
 * @param pathString The path string to extract the path segment from.
 * @param props The {@link IconButtonProps} object representing the icon button.
 * @returns The generated Cypress data-id string.
 */
export const generateCypressDataIdForIconButton = (pathString: string, props: IconButtonProps) => {
  const path = pathString.split("/").at(-1) || "homepage";

  if (typeof props.icon === "object") {
    try {
      const iconName = props.icon.props.type;
      return `icon_button_${path}_${iconName}`;
    } catch (e) {
      return `icon_button_${path}_exception_unknown`;
    }
  }

  return `icon_button_${path}_unknown`;
};

export const getInteger = (path) => {
  return Number(path?.split("path")[1]);
};
export function getCohortTimelineDate(cohort, timelineKey) {
  const timelineKeyDate = cohort?.timelines?.find((timeline) => timeline.key === timelineKey)?.meta
    ?.time;
  return new Date(timelineKeyDate).getTime();
}

export const getNextDeadline = () => {
  const today = dayjs();
  const thisThursday = dayjs().day(4);
  const diff = today.diff(thisThursday, "days");

  const deadline = (
    today.day() === 0
      ? dayjs().add(4, "days")
      : diff <= 0
        ? dayjs().day(4)
        : today.add(7 - today.day() + 4, "days")
  ).format("D MMM");

  return deadline;
};
